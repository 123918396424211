import { APP_BASE_HREF, CommonModule } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import {
    PrivaSegmentConfig,
    PrivaSegmentCoreModule,
    PrivaSegmentService,
    PrivaSegmentUserService,
} from '@priva/analytics/segment';
import {
    AppShellConfiguration,
    AppShellModule,
    AppShellUtilitiesModule,
    PrimaryNavigationService,
} from '@priva/appshell';
import { PrivaAuthCurrentUserBffModule, PrivaAuthCurrentUserService } from '@priva/auth/current-user';
import { PrivaAuthHttpBffModule, PrivaAuthHttpOptions } from '@priva/auth/http';
import { PrivaAuthSignoutModule } from '@priva/auth/signout';
import { PrivaDynamicModule } from '@priva/components/dynamic';
import { PrivaLoaderModule } from '@priva/components/loader';
import { PrivaNotificationsModule } from '@priva/components/notifications';
import { PrivaSpinnerModule } from '@priva/components/spinner';
import { PrivaErrorPageHandlerService } from '@priva/error-pages';
import {
    PrivaLocalizationModule,
    PrivaLocalizationOptions,
    PrivaTranslateModule,
    TranslateLoader,
    VersionBasedTranslationLoader,
} from '@priva/localization';
import { PrivaScrollbarModule } from '@priva/utilities/scrollbar';
import { PrivaScrollToModule } from '@priva/utilities/scrollto';
import { PrivaToggleModule } from '@priva/utilities/toggle';
import { PrivaWindowEventsModule } from '@priva/utilities/window-events';

import { environment } from '../environments/environment';
import { AppMainComponent } from './app-main.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TrackEventsService } from './core/analytics';
import { AppInfoModule } from './core/app-info';
import { AppConfigurationModule } from './core/configuration/app-configuration.module';
import { AppConfigurationService } from './core/configuration/app-configuration.service';
import { ErrorHandlerService } from './core/error/error-handling.service';
import { PrivaHelpInfoModule } from './core/help';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { NavigationModule } from './core/routing/navigation.module';
import { AppPrimaryNavigationService } from './core/routing/primary-navigation.service';
import { StatusErrorPageComponent } from './core/status-error-page';
import { AnalyticsTwinThrobberModule } from './core/throbber';
import { AppState } from './state';
import { hidePageLoader } from './state/actions/app.actions';
import { AppApiEffects, AppEffects, SiteApiEffects, TenantUsersEffect } from './state/effects';
import { rootReducer } from './state/reducers';

export function getCustomAuthOptions(_configService: AppConfigurationService): PrivaAuthHttpOptions {
    const options: PrivaAuthHttpOptions = { bffConfig: { csrfValue: '' } };
    return options;
}

export function getAppShellConfig(configService: AppConfigurationService): AppShellConfiguration {
    return AppShellConfiguration.from(configService.configuration);
}

export function getAppInsightsKey(configService: AppConfigurationService): { instrumentationKey?: string } {
    return {
        instrumentationKey: configService.configuration.analytics.appInsights?.instrumentationKey,
    };
}

export function getCustomLocalizationOptions(
    configService: AppConfigurationService,
): PrivaLocalizationOptions {
    const uris = configService.configuration.uris;
    return {
        path: 'assets/translations',
        version: environment.version,
        availableLanguagesResource: uris.preferencesGetAvailableLanguages,
        preferenceApi: uris.consolidatedPreferences,
        defaultLanguage: 'en-US',
        translationFileSuffix: '.json',
    };
}

export function getVersionBasedTranslationLoader(
    http: HttpClient,
    options: PrivaLocalizationOptions,
): VersionBasedTranslationLoader {
    return new VersionBasedTranslationLoader(http, options);
}

/**
 * Preparation for State Management
 */
export const STORE_CONFIG = {
    runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: true,
        strictActionSerializability: true,
    },
};

/* istanbul ignore next lvb: depends on environment */
export const devImports = [
    StoreDevtoolsModule.instrument({
        maxAge: 250,
        logOnly: false,
    }),
];

@NgModule({
    declarations: [AppMainComponent, AppComponent],
    bootstrap: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        PrivaLoaderModule,
        PrivaSpinnerModule,
        PrivaAuthHttpBffModule.forRoot({
            provide: PrivaAuthHttpOptions,
            useFactory: getCustomAuthOptions,
            deps: [AppConfigurationService],
        }),
        AppRoutingModule,
        PrivaDynamicModule,
        PrivaTranslateModule,
        AnalyticsTwinThrobberModule.forRoot(),
        NavigationModule.forRoot(),
        PrivaAuthCurrentUserBffModule.forRoot(),
        AppConfigurationModule.forRoot(),
        PrivaWindowEventsModule.forRoot(),
        PrivaScrollbarModule.forRoot(),
        AppShellModule.forRoot(
            {
                provide: AppShellConfiguration,
                useFactory: getAppShellConfig,
                deps: [AppConfigurationService],
            },
            [
                AppShellModule.provideDefaultFeatureToggleRouter(),
                { provide: PrimaryNavigationService, useClass: AppPrimaryNavigationService },
            ],
        ),
        AppShellUtilitiesModule,
        PrivaToggleModule.forRoot(),
        PrivaNotificationsModule.forRoot(),
        PrivaScrollToModule.forRoot(),
        AppInfoModule.forRoot(),
        PrivaHelpInfoModule.forRoot(),
        PrivaAuthSignoutModule,
        StatusErrorPageComponent,
        /**
         * Localization, Storage & Translate configuration
         */
        PrivaLocalizationModule.forRoot({
            provide: PrivaLocalizationOptions,
            useFactory: getCustomLocalizationOptions,
            deps: [AppConfigurationService],
        }),
        PrivaTranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: getVersionBasedTranslationLoader,
                deps: [HttpClient, PrivaLocalizationOptions],
            },
        }),
        StoreModule.forRoot(
            {
                ...rootReducer,
                router: routerReducer,
            },
            { ...STORE_CONFIG },
        ),
        EffectsModule.forRoot([TenantUsersEffect, AppEffects, AppApiEffects, SiteApiEffects]),
        StoreRouterConnectingModule.forRoot(),
        PrivaSegmentCoreModule.forRoot([
            {
                provide: PrivaSegmentConfig,
                useFactory: getAnalyticsSegmentConfig,
                deps: [AppConfigurationService],
            },
            {
                provide: PrivaSegmentUserService,
                useExisting: PrivaAuthCurrentUserService,
            },
        ]),
        ...devImports,
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        ErrorHandlerService,
        PrivaErrorPageHandlerService,
        PrivaSegmentService,
        TrackEventsService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    constructor(
        _privaSegmentService: PrivaSegmentService,
        private appStore: Store<AppState>,
    ) {
        this.appStore.dispatch(hidePageLoader());
    }
}

export function getAnalyticsSegmentConfig(configService: AppConfigurationService): PrivaSegmentConfig {
    const analytics = configService.configuration.analytics;
    const segment = analytics ? analytics.segment : undefined;
    const apiKey = segment ? segment.apiKey : '';

    return new PrivaSegmentConfig(apiKey, {
        appName: 'Analytics Building',
        loadOnInitialization: apiKey !== '',
        trackInternalUsers: segment && !!segment.trackInternalUsers,
    });
}
